/*--- Label ---*/

.label-default {
	background: #f3f4f7;
}
.label-primary {
	background: $primary;
}
.label-success {
	background: $success;
}
.label-danger {
	background: #dc0441;
}
.label-warning {
	background: $warning;
}
.label-info {
	background: $info;
}