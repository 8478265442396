/*------------------------------------------------------------------
[Master Stylesheet]

Project        :   Dayone - Multipurpose Admin & Dashboard Template
Version        :   V.1
Create Date    :   23/04/2021
Copyright      :   Spruko Technologies Private Limited 
Author         :   Spruko
Author URL     :   https://themeforest.net/user/spruko
Support	       :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

@import "variables";

/*
1.  Accordions
2.  Alerts
3.  Badges
4.  Bootstrap-Styles
5.  Breadcrumbs
6.  Buttons
7.  Card
8.  Carousel
9.  Dropdown
10. Input-Group
11. Jumbotron
12. Labels
13. List
14. Media
15. Modal
16. Navigation
17. Pagination
18. Panel
19. Progress
20. Tables
21. Tags
22. Thumbnail
23. Tolltip&popover
24. Typography
25. Chat
26. Chat2
27. Contact
28. Date-Picker
29. Loader
30. Popup-chat
31. Profile
32. RangeSlider
33. Rating
34. Select-Group
35. Selectize
36. Tabs
37. Vectormap
38. Avatars
39. Custom-Control
40. Custom-styles
41. Custom
42. Feather-icons
43. Highlight
44. Icon-list
46. Pricing
47. SupportDashboard
48. Footer
49. Grid
50. Header
51. Horizontal-menu
52. rtl
53. support-hor
54. Calendar
53. Charts
54. Email
55. Form-Elements
56. Form-wizard
57. Gallery
58. Time-line
59. accept-cookies
60. accordions
61. bootstrap-datepicker
62. bootstrap-timepicker
63. buttons.bootstrap
64. buttons.dataTables
65. color-picker
66. c3-chart
67. cookies
68. cropme
69. dataTables.bootstrap
70. datepicker
71. dragula
72. fancy_fileupload
73. fileupload
74. fullcalendar
75. gallery
76. intlTelInput
77. ion.rangeSlider
78. Alignments
79. Backgrounds
80. Borders
81. Height
82. Margin
83. Padding
84. Typography
85. Width
86. Boxed
87. Default-menu
87. Dark
87. Transparent
87. Skin-modes
*/

/* ###### Fonts ####### */
@import "custom/fonts/fonts";


/* ###### bootstrap ####### */
@import "bootstrap/accordion";
@import "bootstrap/alerts";
@import "bootstrap/badges";
@import "bootstrap/bootstrap-styles";
@import "bootstrap/breadcrumb";
@import "bootstrap/buttons";
@import "bootstrap/cards";
@import "bootstrap/carousel";
@import "bootstrap/dropdown";
@import "bootstrap/input-group";
@import "bootstrap/jumbotron";
@import "bootstrap/labels";
@import "bootstrap/list";
@import "bootstrap/media";
@import "bootstrap/modal";
@import "bootstrap/navigation";
@import "bootstrap/pagination";
@import "bootstrap/panel";
@import "bootstrap/progress";
@import "bootstrap/tables";
@import "bootstrap/tags";
@import "bootstrap/thumbnail";
@import "bootstrap/ribbons";
@import "bootstrap/tooltip&popover";
@import "bootstrap/typhography";


/* ###### components ####### */
@import "components/chat";
@import "components/chat2";
@import "components/contact";
@import "components/date-picker";
@import "components/loader";
@import "components/popup-chat";
@import "components/profile";
@import "components/rangeslider";
@import "components/rating";
@import "components/select-group";
@import "components/selectize";
@import "components/tabs";
@import "components/vectormap";


/* ###### custom ####### */
@import "custom/avatars";
@import "custom/custom-control";
@import "custom/custom-styles";
@import "custom/custom";
@import "custom/feather-icons";
@import "custom/highlight";
@import "custom/icon-list";
@import "custom/pricing";
@import "custom/supportdashboard";


/* ###### layouts ####### */
@import "layouts/boxed";
@import "layouts/footer";
@import "layouts/header";
@import "layouts/horizontal-menu";
@import "layouts/grid";
@import "layouts/dark";
@import "layouts/rtl";
@import "layouts/skin-modes";
@import "layouts/support-hor";
@import "layouts/transparent-style";


/* ###### library ####### */
@import "library/calendar";
@import "library/charts";
@import "library/email";
@import "library/form-elements";
@import "library/form-wizard";
@import "library/gallery";
@import "library/timeline";


/* ###### sidemenu ####### */
@import "sidemenu/default-menu";


/* ###### utlities ####### */
@import "utlities/alignments";
@import "utlities/backgrounds";
@import "utlities/borders";
@import "utlities/height";
@import "utlities/margin";
@import "utlities/padding";
@import "utlities/typography";
@import "utlities/width";










































