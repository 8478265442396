/*------ Paddings -------*/

.p-0 {
	padding: 0 !important;
}
.pt-0, .py-0 {
	padding-top: 0 !important;
}
.pe-0, .px-0 {
	padding-right: 0 !important;
}
.pb-0, .py-0 {
	padding-bottom: 0 !important;
}
.ps-0, .px-0 {
	padding-left: 0 !important;
}
.p-1 {
	padding: 0.25rem;
}
.pt-1, .py-1 {
	padding-top: 0.25rem !important;
}
.pe-1, .px-1 {
	padding-right: 0.25rem !important;
}
.pb-1, .py-1 {
	padding-bottom: 0.25rem !important;
}
.ps-1, .px-1 {
	padding-left: 0.25rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.pt-2, .py-2 {
	padding-top: 0.5rem !important;
}
.pe-2, .px-2 {
	padding-right: 0.5rem !important;
}
.pb-2, .py-2 {
	padding-bottom: 0.5rem !important;
}
.ps-2, .px-2 {
	padding-left: 0.5rem !important;
}
.p-3 {
	padding: 0.75rem ;
}
.pt-3, .py-3 {
	padding-top: 0.75rem !important;
}
.pe-3, .px-3 {
	padding-right: 0.75rem !important;
}
.pb-3, .py-3 {
	padding-bottom: 0.75rem !important;
}
.ps-3, .px-3 {
	padding-left: 0.75rem !important;
}
.p-4 {
	padding: 1rem  !important;
}
.pt-4, .py-4 {
	padding-top: 1rem !important;
}
.pe-4, .px-4 {
	padding-right: 1rem !important;
}
.pb-4, .py-4 {
	padding-bottom: 1rem !important;
}
.ps-4, .px-4 {
	padding-left: 1rem !important;
}
.p-5 {
	padding: 1.5rem !important;
}
.pt-5, .py-5 {
	padding-top: 1.5rem !important;
}
.pe-5, .px-5 {
	padding-right: 1.5rem !important;
}
.pb-5, .py-5 {
	padding-bottom: 1.5rem !important;
}
.ps-5, .px-5 {
	padding-left: 1.5rem !important;
}
.p-6 {
	padding: 2rem !important;
}
.pt-6, .py-6 {
	padding-top: 2rem !important;
}
.pe-6, .px-6 {
	padding-right: 2rem !important;
}
.pb-6, .py-6 {
	padding-bottom: 2rem !important;
}
.ps-6, .px-6 {
	padding-left: 2rem !important;
}
.p-7 {
	padding: 3rem !important;
}
.pt-7, .py-7 {
	padding-top: 3rem !important;
}
.pe-7, .px-7 {
	padding-right: 3rem !important;
}
.pb-7, .py-7 {
	padding-bottom: 3rem !important;
}
.ps-7, .px-7 {
	padding-left: 3rem !important;
}
.p-8 {
	padding: 4rem !important;
}
.pt-8, .py-8 {
	padding-top: 4rem !important;
}
.pe-8, .px-8 {
	padding-right: 4rem !important;
}
.pb-8, .py-8 {
	padding-bottom: 4rem !important;
}
.ps-8, .px-8 {
	padding-left: 4rem !important;
}
.p-9 {
	padding: 6rem !important;
}
.pt-9, .py-9 {
	padding-top: 6rem !important;
}
.pe-9, .px-9 {
	padding-right: 6rem !important;
}
.pb-9, .py-9 {
	padding-bottom: 6rem !important;
}
.ps-9, .px-9 {
	padding-left: 6rem !important;
}
@media (min-width: 576px) {
    .p-sm-0 {
		padding: 0 !important;
	}
	.pt-sm-0, .py-sm-0 {
		padding-top: 0 !important;
	}
	.pe-sm-0, .px-sm-0 {
		padding-right: 0 !important;
	}
	.pb-sm-0, .py-sm-0 {
		padding-bottom: 0 !important;
	}
	.ps-sm-0, .px-sm-0 {
		padding-left: 0 !important;
	}
	.p-sm-1 {
		padding: 0.25rem !important;
	}
	.pt-sm-1, .py-sm-1 {
		padding-top: 0.25rem !important;
	}
	.pe-sm-1, .px-sm-1 {
		padding-right: 0.25rem !important;
	}
	.pb-sm-1, .py-sm-1 {
		padding-bottom: 0.25rem !important;
	}
	.ps-sm-1, .px-sm-1 {
		padding-left: 0.25rem !important;
	}
	.p-sm-2 {
		padding: 0.5rem !important;
	}
	.pt-sm-2, .py-sm-2 {
		padding-top: 0.5rem !important;
	}
	.pe-sm-2, .px-sm-2 {
		padding-right: 0.5rem !important;
	}
	.pb-sm-2, .py-sm-2 {
		padding-bottom: 0.5rem !important;
	}
	.ps-sm-2, .px-sm-2 {
		padding-left: 0.5rem !important;
	}
	.p-sm-3 {
		padding: 0.75rem !important;
	}
	.pt-sm-3, .py-sm-3 {
		padding-top: 0.75rem !important;
	}
	.pe-sm-3, .px-sm-3 {
		padding-right: 0.75rem !important;
	}
	.pb-sm-3, .py-sm-3 {
		padding-bottom: 0.75rem !important;
	}
	.ps-sm-3, .px-sm-3 {
		padding-left: 0.75rem !important;
	}
	.p-sm-4 {
		padding: 1rem !important;
	}
	.pt-sm-4, .py-sm-4 {
		padding-top: 1rem !important;
	}
	.pe-sm-4, .px-sm-4 {
		padding-right: 1rem !important;
	}
	.pb-sm-4, .py-sm-4 {
		padding-bottom: 1rem !important;
	}
	.ps-sm-4, .px-sm-4 {
		padding-left: 1rem !important;
	}
	.p-sm-5 {
		padding: 1.5rem !important;
	}
	.pt-sm-5, .py-sm-5 {
		padding-top: 1.5rem !important;
	}
	.pe-sm-5, .px-sm-5 {
		padding-right: 1.5rem !important;
	}
	.pb-sm-5, .py-sm-5 {
		padding-bottom: 1.5rem !important;
	}
	.ps-sm-5, .px-sm-5 {
		padding-left: 1.5rem !important;
	}
	.p-sm-6 {
		padding: 2rem !important;
	}
	.pt-sm-6, .py-sm-6 {
		padding-top: 2rem !important;
	}
	.pe-sm-6, .px-sm-6 {
		padding-right: 2rem !important;
	}
	.pb-sm-6, .py-sm-6 {
		padding-bottom: 2rem !important;
	}
	.ps-sm-6, .px-sm-6 {
		padding-left: 2rem !important;
	}
	.p-sm-7 {
		padding: 3rem !important;
	}
	.pt-sm-7, .py-sm-7 {
		padding-top: 3rem !important;
	}
	.pe-sm-7, .px-sm-7 {
		padding-right: 3rem !important;
	}
	.pb-sm-7, .py-sm-7 {
		padding-bottom: 3rem !important;
	}
	.ps-sm-7, .px-sm-7 {
		padding-left: 3rem !important;
	}
	.p-sm-8 {
		padding: 4rem !important;
	}
	.pt-sm-8, .py-sm-8 {
		padding-top: 4rem !important;
	}
	.pe-sm-8, .px-sm-8 {
		padding-right: 4rem !important;
	}
	.pb-sm-8, .py-sm-8 {
		padding-bottom: 4rem !important;
	}
	.ps-sm-8, .px-sm-8 {
		padding-left: 4rem !important;
	}
	.p-sm-9 {
		padding: 6rem !important;
	}
	.pt-sm-9, .py-sm-9 {
		padding-top: 6rem !important;
	}
	.pe-sm-9, .px-sm-9 {
		padding-right: 6rem !important;
	}
	.pb-sm-9, .py-sm-9 {
		padding-bottom: 6rem !important;
	}
	.ps-sm-9, .px-sm-9 {
		padding-left: 6rem !important;
	}
}
@media (min-width: 768px) {
    .p-md-0 {
		padding: 0 !important;
	}
	.pt-md-0, .py-md-0 {
		padding-top: 0 !important;
	}
	.pe-md-0, .px-md-0 {
		padding-right: 0 !important;
	}
	.pb-md-0, .py-md-0 {
		padding-bottom: 0 !important;
	}
	.ps-md-0, .px-md-0 {
		padding-left: 0 !important;
	}
	.p-md-1 {
		padding: 0.25rem !important;
	}
	.pt-md-1, .py-md-1 {
		padding-top: 0.25rem !important;
	}
	.pe-md-1, .px-md-1 {
		padding-right: 0.25rem !important;
	}
	.pb-md-1, .py-md-1 {
		padding-bottom: 0.25rem !important;
	}
	.ps-md-1, .px-md-1 {
		padding-left: 0.25rem !important;
	}
	.p-md-2 {
		padding: 0.5rem !important;
	}
	.pt-md-2, .py-md-2 {
		padding-top: 0.5rem !important;
	}
	.pe-md-2, .px-md-2 {
		padding-right: 0.5rem !important;
	}
	.pb-md-2, .py-md-2 {
		padding-bottom: 0.5rem !important;
	}
	.ps-md-2, .px-md-2 {
		padding-left: 0.5rem !important;
	}
	.p-md-3 {
		padding: 0.75rem !important;
	}
	.pt-md-3, .py-md-3 {
		padding-top: 0.75rem !important;
	}
	.pe-md-3, .px-md-3 {
		padding-right: 0.75rem !important;
	}
	.pb-md-3, .py-md-3 {
		padding-bottom: 0.75rem !important;
	}
	.ps-md-3, .px-md-3 {
		padding-left: 0.75rem !important;
	}
	.p-md-4 {
		padding: 1rem !important;
	}
	.pt-md-4, .py-md-4 {
		padding-top: 1rem !important;
	}
	.pe-md-4, .px-md-4 {
		padding-right: 1rem !important;
	}
	.pb-md-4, .py-md-4 {
		padding-bottom: 1rem !important;
	}
	.ps-md-4, .px-md-4 {
		padding-left: 1rem !important;
	}
	.p-md-5 {
		padding: 1.5rem !important;
	}
	.pt-md-5, .py-md-5 {
		padding-top: 1.5rem !important;
	}
	.pe-md-5, .px-md-5 {
		padding-right: 1.5rem !important;
	}
	.pb-md-5, .py-md-5 {
		padding-bottom: 1.5rem !important;
	}
	.ps-md-5, .px-md-5 {
		padding-left: 1.5rem !important;
	}
	.p-md-6 {
		padding: 2rem !important;
	}
	.pt-md-6, .py-md-6 {
		padding-top: 2rem !important;
	}
	.pe-md-6, .px-md-6 {
		padding-right: 2rem !important;
	}
	.pb-md-6, .py-md-6 {
		padding-bottom: 2rem !important;
	}
	.ps-md-6, .px-md-6 {
		padding-left: 2rem !important;
	}
	.p-md-7 {
		padding: 3rem !important;
	}
	.pt-md-7, .py-md-7 {
		padding-top: 3rem !important;
	}
	.pe-md-7, .px-md-7 {
		padding-right: 3rem !important;
	}
	.pb-md-7, .py-md-7 {
		padding-bottom: 3rem !important;
	}
	.ps-md-7, .px-md-7 {
		padding-left: 3rem !important;
	}
	.p-md-8 {
		padding: 4rem !important;
	}
	.pt-md-8, .py-md-8 {
		padding-top: 4rem !important;
	}
	.pe-md-8, .px-md-8 {
		padding-right: 4rem !important;
	}
	.pb-md-8, .py-md-8 {
		padding-bottom: 4rem !important;
	}
	.ps-md-8, .px-md-8 {
		padding-left: 4rem !important;
	}
	.p-md-9 {
		padding: 6rem !important;
	}
	.pt-md-9, .py-md-9 {
		padding-top: 6rem !important;
	}
	.pe-md-9, .px-md-9 {
		padding-right: 6rem !important;
	}
	.pb-md-9, .py-md-9 {
		padding-bottom: 6rem !important;
	}
	.ps-md-9, .px-md-9 {
		padding-left: 6rem !important;
	}
}
@media (min-width: 992px) {
    .p-lg-0 {
		padding: 0 !important;
	}
	.pt-lg-0, .py-lg-0 {
		padding-top: 0 !important;
	}
	.pe-lg-0, .px-lg-0 {
		padding-right: 0 !important;
	}
	.pb-lg-0, .py-lg-0 {
		padding-bottom: 0 !important;
	}
	.ps-lg-0, .px-lg-0 {
		padding-left: 0 !important;
	}
	.p-lg-1 {
		padding: 0.25rem !important;
	}
	.pt-lg-1, .py-lg-1 {
		padding-top: 0.25rem !important;
	}
	.pe-lg-1, .px-lg-1 {
		padding-right: 0.25rem !important;
	}
	.pb-lg-1, .py-lg-1 {
		padding-bottom: 0.25rem !important;
	}
	.ps-lg-1, .px-lg-1 {
		padding-left: 0.25rem !important;
	}
	.p-lg-2 {
		padding: 0.5rem !important;
	}
	.pt-lg-2, .py-lg-2 {
		padding-top: 0.5rem !important;
	}
	.pe-lg-2, .px-lg-2 {
		padding-right: 0.5rem !important;
	}
	.pb-lg-2, .py-lg-2 {
		padding-bottom: 0.5rem !important;
	}
	.ps-lg-2, .px-lg-2 {
		padding-left: 0.5rem !important;
	}
	.p-lg-3 {
		padding: 0.75rem !important;
	}
	.pt-lg-3, .py-lg-3 {
		padding-top: 0.75rem !important;
	}
	.pe-lg-3, .px-lg-3 {
		padding-right: 0.75rem !important;
	}
	.pb-lg-3, .py-lg-3 {
		padding-bottom: 0.75rem !important;
	}
	.ps-lg-3, .px-lg-3 {
		padding-left: 0.75rem !important;
	}
	.p-lg-4 {
		padding: 1rem !important;
	}
	.pt-lg-4, .py-lg-4 {
		padding-top: 1rem !important;
	}
	.pe-lg-4, .px-lg-4 {
		padding-right: 1rem !important;
	}
	.pb-lg-4, .py-lg-4 {
		padding-bottom: 1rem !important;
	}
	.ps-lg-4, .px-lg-4 {
		padding-left: 1rem !important;
	}
	.p-lg-5 {
		padding: 1.5rem !important;
	}
	.pt-lg-5, .py-lg-5 {
		padding-top: 1.5rem !important;
	}
	.pe-lg-5, .px-lg-5 {
		padding-right: 1.5rem !important;
	}
	.pb-lg-5, .py-lg-5 {
		padding-bottom: 1.5rem !important;
	}
	.ps-lg-5, .px-lg-5 {
		padding-left: 1.5rem !important;
	}
	.p-lg-6 {
		padding: 2rem !important;
	}
	.pt-lg-6, .py-lg-6 {
		padding-top: 2rem !important;
	}
	.pe-lg-6, .px-lg-6 {
		padding-right: 2rem !important;
	}
	.pb-lg-6, .py-lg-6 {
		padding-bottom: 2rem !important;
	}
	.ps-lg-6, .px-lg-6 {
		padding-left: 2rem !important;
	}
	.p-lg-7 {
		padding: 3rem !important;
	}
	.pt-lg-7, .py-lg-7 {
		padding-top: 3rem !important;
	}
	.pe-lg-7, .px-lg-7 {
		padding-right: 3rem !important;
	}
	.pb-lg-7, .py-lg-7 {
		padding-bottom: 3rem !important;
	}
	.ps-lg-7, .px-lg-7 {
		padding-left: 3rem !important;
	}
	.p-lg-8 {
		padding: 4rem !important;
	}
	.pt-lg-8, .py-lg-8 {
		padding-top: 4rem !important;
	}
	.pe-lg-8, .px-lg-8 {
		padding-right: 4rem !important;
	}
	.pb-lg-8, .py-lg-8 {
		padding-bottom: 4rem !important;
	}
	.ps-lg-8, .px-lg-8 {
		padding-left: 4rem !important;
	}
	.p-lg-9 {
		padding: 6rem !important;
	}
	.pt-lg-9, .py-lg-9 {
		padding-top: 6rem !important;
	}
	.pe-lg-9, .px-lg-9 {
		padding-right: 6rem !important;
	}
	.pb-lg-9, .py-lg-9 {
		padding-bottom: 6rem !important;
	}
	.ps-lg-9, .px-lg-9 {
		padding-left: 6rem !important;
	}
}
@media (min-width: 1280px) {
    .p-xl-0 {
		padding: 0 !important;
	}
	.pt-xl-0, .py-xl-0 {
		padding-top: 0 !important;
	}
	.pe-xl-0, .px-xl-0 {
		padding-right: 0 !important;
	}
	.pb-xl-0, .py-xl-0 {
		padding-bottom: 0 !important;
	}
	.ps-xl-0, .px-xl-0 {
		padding-left: 0 !important;
	}
	.p-xl-1 {
		padding: 0.25rem !important;
	}
	.pt-xl-1, .py-xl-1 {
		padding-top: 0.25rem !important;
	}
	.pe-xl-1, .px-xl-1 {
		padding-right: 0.25rem !important;
	}
	.pb-xl-1, .py-xl-1 {
		padding-bottom: 0.25rem !important;
	}
	.ps-xl-1, .px-xl-1 {
		padding-left: 0.25rem !important;
	}
	.p-xl-2 {
		padding: 0.5rem !important;
	}
	.pt-xl-2, .py-xl-2 {
		padding-top: 0.5rem !important;
	}
	.pe-xl-2, .px-xl-2 {
		padding-right: 0.5rem !important;
	}
	.pb-xl-2, .py-xl-2 {
		padding-bottom: 0.5rem !important;
	}
	.ps-xl-2, .px-xl-2 {
		padding-left: 0.5rem !important;
	}
	.p-xl-3 {
		padding: 0.75rem !important;
	}
	.pt-xl-3, .py-xl-3 {
		padding-top: 0.75rem !important;
	}
	.pe-xl-3, .px-xl-3 {
		padding-right: 0.75rem !important;
	}
	.pb-xl-3, .py-xl-3 {
		padding-bottom: 0.75rem !important;
	}
	.ps-xl-3, .px-xl-3 {
		padding-left: 0.75rem !important;
	}
	.p-xl-4 {
		padding: 1rem !important;
	}
	.pt-xl-4, .py-xl-4 {
		padding-top: 1rem !important;
	}
	.pe-xl-4, .px-xl-4 {
		padding-right: 1rem !important;
	}
	.pb-xl-4, .py-xl-4 {
		padding-bottom: 1rem !important;
	}
	.ps-xl-4, .px-xl-4 {
		padding-left: 1rem !important;
	}
	.p-xl-5 {
		padding: 1.5rem !important;
	}
	.pt-xl-5, .py-xl-5 {
		padding-top: 1.5rem !important;
	}
	.pe-xl-5, .px-xl-5 {
		padding-right: 1.5rem !important;
	}
	.pb-xl-5, .py-xl-5 {
		padding-bottom: 1.5rem !important;
	}
	.ps-xl-5, .px-xl-5 {
		padding-left: 1.5rem !important;
	}
	.p-xl-6 {
		padding: 2rem !important;
	}
	.pt-xl-6, .py-xl-6 {
		padding-top: 2rem !important;
	}
	.pe-xl-6, .px-xl-6 {
		padding-right: 2rem !important;
	}
	.pb-xl-6, .py-xl-6 {
		padding-bottom: 2rem !important;
	}
	.ps-xl-6, .px-xl-6 {
		padding-left: 2rem !important;
	}
	.p-xl-7 {
		padding: 3rem !important;
	}
	.pt-xl-7, .py-xl-7 {
		padding-top: 3rem !important;
	}
	.pe-xl-7, .px-xl-7 {
		padding-right: 3rem !important;
	}
	.pb-xl-7, .py-xl-7 {
		padding-bottom: 3rem !important;
	}
	.ps-xl-7, .px-xl-7 {
		padding-left: 3rem !important;
	}
	.p-xl-8 {
		padding: 4rem !important;
	}
	.pt-xl-8, .py-xl-8 {
		padding-top: 4rem !important;
	}
	.pe-xl-8, .px-xl-8 {
		padding-right: 4rem !important;
	}
	.pb-xl-8, .py-xl-8 {
		padding-bottom: 4rem !important;
	}
	.ps-xl-8, .px-xl-8 {
		padding-left: 4rem !important;
	}
	.p-xl-9 {
		padding: 6rem !important;
	}
	.pt-xl-9, .py-xl-9 {
		padding-top: 6rem !important;
	}
	.pe-xl-9, .px-xl-9 {
		padding-right: 6rem !important;
	}
	.pb-xl-9, .py-xl-9 {
		padding-bottom: 6rem !important;
	}
	.ps-xl-9, .px-xl-9 {
		padding-left: 6rem !important;
	}
}