/*--- Pagination ---*/
.example .pagination {
	margin-bottom: 0;
}
.pagination {
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: 3px;
}
.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	background-color: $white;
	border: $border;
	color: $color;
	box-shadow: none;
	&:hover {
		z-index: 2;
		color: #295a9f;
		text-decoration: none;
		background-color: rgba(70, 127, 207, 0.06);
		box-shadow: none;
		outline: 0;
	}
	&:focus {
		z-index: 2;
		outline: 0;
		box-shadow: none;
	}
	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}
.page-item {
	&:first-child .page-link {
		margin-left: 0;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}
	&:last-child .page-link {
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}
	&.active .page-link {
		z-index: 1;
		color: $white;
		background-color: $primary;
		border-color: $primary;
		&:hover {
			color: $white !important;
		}
	}
	&.disabled .page-link {
		color: #ced4da;
		pointer-events: none;
		cursor: auto;
		background-color: $white;
		border-color: #e9ebfa;
	}
}
.pagination-lg {
	.page-link {
		padding: 0.75rem 1.5rem;
		font-size: 1.125rem;
		line-height: 1.5;
	}
	.page-item {
		&:first-child .page-link {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child .page-link {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}
.pagination-sm {
	.page-link {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
	}
	.page-item {
		&:first-child .page-link {
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
		&:last-child .page-link {
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;
		}
	}
}