.fc-view {
	&.fc-listMonth-view, &.fc-listWeek-view {
		border-width: 0;
	}
	&.fc-listMonth-view .fc-scroller, &.fc-listWeek-view .fc-scroller {
		padding: 0 20px 20px;
	}
	&.fc-listMonth-view .fc-list-table, &.fc-listWeek-view .fc-list-table {
		border-collapse: separate;
		display: block;
	}
	&.fc-listMonth-view .fc-list-table>tbody, &.fc-listWeek-view .fc-list-table>tbody {
		display: flex;
		flex-wrap: wrap;
	}
	&.fc-listMonth-view .fc-list-heading, &.fc-listWeek-view .fc-list-heading {
		flex: 0 0 20%;
		max-width: 20%;
		margin-top: 15px;
	}
	&.fc-listMonth-view .fc-list-heading:first-child, &.fc-listWeek-view .fc-list-heading:first-child, &.fc-listMonth-view .fc-list-heading:first-child+.fc-list-item, &.fc-listWeek-view .fc-list-heading:first-child+.fc-list-item {
		margin-top: 0;
	}
	&.fc-listMonth-view .fc-list-heading td, &.fc-listWeek-view .fc-list-heading td {
		background-color: transparent;
		border-width: 0;
		padding: 0;
	}
	&.fc-listMonth-view .fc-list-heading-main, &.fc-listWeek-view .fc-list-heading-main {
		display: block;
		font-size: 11px;
		text-transform: uppercase;
		color: #737688;
		font-weight: 600;
		padding: 5px;
		text-align: center;
	}
	&.fc-listMonth-view .fc-list-heading-main span:last-child, &.fc-listWeek-view .fc-list-heading-main span:last-child {
		display: block;
		font-size: 30px;
		font-weight: 700;
		font-family: Helvetica, Arial, sans-serif;
		letter-spacing: -1px;
		color: #495057;
		line-height: 1;
	}
	&.fc-listMonth-view .fc-list-heading-main.now, &.fc-listWeek-view .fc-list-heading-main.now, &.fc-listMonth-view .fc-list-heading-main.now span:last-child, &.fc-listWeek-view .fc-list-heading-main.now span:last-child {
		color: #4c84ff;
	}
	&.fc-listMonth-view .fc-list-item, &.fc-listWeek-view .fc-list-item {
		flex: 0 0 calc(80% - 5px);
		max-width: calc(80% - 5px);
		dispLay: flex;
		flex-direction: column;
		border-left: 4px solid transparent;
		background-color: $white;
		margin-top: 15px;
	}
	&.fc-listMonth-view .fc-list-item {
		&:hover, &:focus {
			cursor: pointer;
		}
	}
	&.fc-listWeek-view .fc-list-item {
		&:hover, &:focus {
			cursor: pointer;
		}
	}
	&.fc-listMonth-view .fc-list-item {
		&:hover td, &:focus td {
			background-color: transparent;
		}
	}
	&.fc-listWeek-view .fc-list-item {
		&:hover td, &:focus td {
			background-color: transparent;
		}
	}
	&.fc-listMonth-view .fc-list-item>td, &.fc-listWeek-view .fc-list-item>td {
		border-width: 0;
	}
	&.fc-listMonth-view .fc-list-item>td.fc-list-item-time, &.fc-listWeek-view .fc-list-item>td.fc-list-item-time {
		border-top: $border;
		border-right: $border;
		box-sizing: border-box;
		color: #495057 !important;
	}
	&.fc-listMonth-view .fc-list-item>td.fc-list-item-title, &.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
		border-bottom: 1px solid #e9edf4;
		border-right: 1px solid #e9edf4;
		box-sizing: border-box;
	}
	&.fc-listMonth-view .fc-list-item+.fc-list-item, &.fc-listWeek-view .fc-list-item+.fc-list-item {
		margin-left: 20%;
	}
	&.fc-listMonth-view .fc-list-item-marker, &.fc-listWeek-view .fc-list-item-marker {
		display: none;
	}
	&.fc-listMonth-view .fc-list-item-time, &.fc-listWeek-view .fc-list-item-time {
		padding: 12px 15px 2px;
		font-size: 13px;
		text-transform: capitalize;
		width: 100%;
	}
	&.fc-listMonth-view .fc-list-item-title, &.fc-listWeek-view .fc-list-item-title {
		padding: 0 15px 12px;
	}
	&.fc-listMonth-view .fc-list-item-title a, &.fc-listWeek-view .fc-list-item-title a {
		display: block;
		font-weight: 700;
		font-size: 15px;
		margin-bottom: 5px;
		color: #14112d;
	}
	&.fc-listMonth-view .fc-list-item-title .fc-desc, &.fc-listWeek-view .fc-list-item-title .fc-desc {
		font-size: 13px;
		line-height: 1.375;
		display: block;
	}
}
@media (min-width: 992px) {
	.fc-view {
		&.fc-listMonth-view .fc-scroller, &.fc-listWeek-view .fc-scroller {
			padding: 0;
		}
	}
}
@media (min-width: 576px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-heading, &.fc-listWeek-view .fc-list-heading {
			flex: 0 0 15%;
			max-width: 15%;
		}
	}
}
@media (min-width: 768px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-heading, &.fc-listWeek-view .fc-list-heading {
			flex: 0 0 12%;
			max-width: 12%;
		}
	}
}
@media (min-width: 992px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-heading, &.fc-listWeek-view .fc-list-heading {
			flex: 0 0 10%;
			max-width: 10%;
		}
	}
}
@media (min-width: 1200px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-heading, &.fc-listWeek-view .fc-list-heading {
			flex: 0 0 8%;
			max-width: 8%;
		}
	}
}
@media (min-width: 576px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-item, &.fc-listWeek-view .fc-list-item {
			flex: 0 0 calc(85% - 5px);
			max-width: calc(85% - 5px);
		}
	}
}
@media (min-width: 768px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-item, &.fc-listWeek-view .fc-list-item {
			flex: 0 0 calc(88% - 5px);
			max-width: calc(88% - 5px);
		}
	}
}
@media (min-width: 992px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-item, &.fc-listWeek-view .fc-list-item {
			flex: 0 0 calc(90% - 5px);
			max-width: calc(90% - 5px);
		}
	}
}
@media (min-width: 1200px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-item, &.fc-listWeek-view .fc-list-item {
			flex: 0 0 calc(92% - 5px);
			max-width: calc(92% - 5px);
		}
	}
}
@media (min-width: 576px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-item+.fc-list-item, &.fc-listWeek-view .fc-list-item+.fc-list-item {
			margin-left: 15%;
		}
	}
}
@media (min-width: 768px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-item+.fc-list-item, &.fc-listWeek-view .fc-list-item+.fc-list-item {
			margin-left: 12%;
		}
	}
}
@media (min-width: 992px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-item+.fc-list-item, &.fc-listWeek-view .fc-list-item+.fc-list-item {
			margin-left: 10%;
		}
	}
}
@media (min-width: 1200px) {
	.fc-view {
		&.fc-listMonth-view .fc-list-item+.fc-list-item, &.fc-listWeek-view .fc-list-item+.fc-list-item {
			margin-left: 8%;
		}
	}
}
td.fc-today {
	background-color: #f3f7fd;
}

/*--- Custom-Calendar ----*/
.custom-calendar{
	.pignose-calendar-body{
		padding: 0 !important;
	}
	.pignose-calendar .pignose-calendar-unit{
		height: 3em !important;
		line-height: 3em !important;
	}
}

.cal1 {
	.clndr {
		.clndr-table {
			.header-days {
				.header-day {
					border-left: 1px solid rgba(23, 183, 148, 0.1);
					border-top: 1px solid #e2e3e4;
				}
				background: $primary;
			}
			tr {
				.empty, .adjacent-month, .my-empty, .my-adjacent-month {
					border-left: $border;
					border-top: $border;
					background: #ebeff8;
				}
				.day {
					&.event, &.my-event {
						background: #eeeef5;
					}
					&.today.event, &.my-today.event {
						background: #f2ecff;
					}
					&.event:hover, &.my-event:hover {
						background: #e5e9f2 !important;
					}
					&.today, &.my-today {
						background: #e5e9f2 !important;
						color: $primary !important;
					}
					border-left: $border;
					border-top: $border;
					&:last-child {
						border-right: $border;
					}
					&:hover {
						background: #e5e9f2;
					}
				}
			}
		}
		.clndr-controls .clndr-control-button {
			.clndr-previous-button {
				color: $primary;
				background: #ebeff8;
			}
			.clndr-next-button {
				color: $primary;
				background: #ebeff8;
				&:hover {
					background: $primary;
				}
			}
			.clndr-previous-button:hover {
				background: $primary;
			}
		}
	}
	font-size: 14px;
	border: $border;
}
.fc button {
	background: #f3f4f7;
	color: #efefef;
	text-transform: uppercase;
	font-size: 13px !important;
	font-weight: 500;
}
.fc-unthemed .fc-divider {
	border-color: #e9ebfa;
}
.fc-event {
	border: 1px solid rgba(51, 102, 255, .2);
	background-color: rgba(51, 102, 255, .2);
}
.fc-event-dot {
	background-color: $primary;
}
.fc-unthemed {
	.fc-divider, .fc-list-heading td, .fc-popover .fc-header {
		background: #ebeff8;
	}
}
.fc-toolbar {
	.fc-state-active, .ui-state-active {
		background: $primary;
		color: $white;
	}
}
.fc-today-button fc-button fc-state-default fc-corner-left fc-corner-right fc-state-disabled:focus {
	border: none !important;
	box-shadow: none !important;
}
.fc-unthemed .fc-list-item:hover td {
	background-color: #ebeff8;
}