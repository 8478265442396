body.active.main-content.main {
    overflow: hidden;
}
.main-content.main {
    /*==============================================================================
								  Start Mobile CSS
	===============================================================================*/

    /* ================== Mobile Menu Change Brake Point ================== */

    @media only screen and (max-width: 991px) {
        /* ================== Mobile Base CSS ================== */

      

        /* ================== Mobile Main Menu CSS ================== */

        .desktoplogo {
            display: none;
        }

        .horizontal-main {
            height: 0;
            padding: 0;
        }

        .horizontalMenucontainer {
            background-attachment: local;
            background-position: 33% 0%;
        }

        .horizontalMenu {
            width: 100%;
            background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
            left: 0;
            overflow-y: hidden;
            padding: 0;
            top: 0;
            position: fixed;
            visibility: hidden;
            margin: 0px;

            > .horizontalMenu-list {
                height: auto;
                min-height: 100%;
                width: 240px;
                background: #17263a;
                padding-bottom: 0;
                margin-left: -240px;
                display: block;
                text-align: center;
                border-right: 1px solid rgba(255, 255, 255, 0.1);
                box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
                > li {
                    width: 100%;
                    display: block;
                    float: none;
                    border-right: none;
                    background-color: transparent;
                    position: relative;
                    white-space: inherit;
                    clear: right;
                    padding: 0;
                    margin-top:3px;

                    span a {
                        width: 85%;
                        margin: 0 auto;
                        top:10px;
                    }

                    > a {
                        padding: 10px 32px 10px 17px;
                        font-size: 14px;
                        text-align: left;
                        border-right: solid 0px;
                        color: #fff;
                        line-height: 25px;
                        border-bottom: 1px solid;
                        border-bottom-color: rgba(255, 255, 255, 0.1);
                        position: static;
                        margin: 0px 0;
                        border-radius: 0px;
                        span.i {
                            display: none;
                        }
                    }

                    a.menuhomeicon {
                        padding-left: 17px;
                        padding-right: 17px;
                        border-top: solid 1px rgba(0, 0, 0, 0.13);
                    }

                    > a {
                        > .fa {
                            font-size: 16px;
                            color: $white;
                            display: none !important;
                        }

                        .arrow:after {
                            display: none;
                        }
                    }

                    &:hover > a {
                        text-decoration: none;
                    }

                    > {

                        ul.sub-menu {
                            display: none;
                            position: relative;
                            top: 0px;
                            background-color: #1c2e46;
                            border: none;
                            padding: 0 25px;
                            opacity: 1;
                            visibility: visible;
                            -webkit-transform: none;
                            -moz-transform: none;
                            -ms-transform: none;
                            -o-transform: none;
                            transform: none;
                            -webkit-transition: inherit;
                            -moz-transition: inherit;
                            transition: inherit;
                            -webkit-transform-style: flat;
                            box-shadow: none;
                            border-bottom: 0px solid #e9ebfa;
                            margin: 0px;
                            border-radius: 0;
                            > li {
                                > a {
                                    line-height: 20px;
                                    font-size: 13px;
                                    padding: 10px 0px 10px 16px;
                                    position: initial;
                                    color: #fff;
                                }

                                span + a {
                                    padding-right: 30px;
                                }

                                > a {
                                    > .fa {
                                        display: none;
                                    }

                                    &:hover {
                                        background-color: transparent;
                                        text-decoration: none;
                                    }
                                }
                            }

                            li:hover > a {
                                color: #576482;
                            }

                            > li > ul.sub-menu {
                                width: 100%;
                                position: static;
                                left: 100%;
                                top: 0;
                                display: none;
                                margin: 0px;
                                padding: 0px 30px;
                                border: solid 0px;
                                transform: none;
                                opacity: 1;
                                visibility: visible;
                                -webkit-transform: none;
                                -moz-transform: none;
                                -ms-transform: none;
                                -o-transform: none;
                                transform: none;
                                -webkit-transition: inherit;
                                -moz-transition: inherit;
                                transition: inherit;
                                -webkit-transform-style: flat;
                                background: transparent;
                                box-shadow: none;
                                border: 0;
                                border-radius: 0;
                                > li {
                                    margin: 0px 0px 0px 0px;
                                    padding: 0px;
                                    position: relative;

                                    > a {
                                        line-height: 20px;
                                        font-size: 13px;
                                        padding: 10px 0px 10px 6px;
                                        color: #576482;
                                    }

                                    span + a {
                                        padding-right: 30px;
                                    }

                                    > a {
                                        > .fa {
                                            display: none;
                                        }

                                        &:hover {
                                            background-color: transparent;
                                            color: #576482;
                                            text-decoration: none;
                                        }

                                        &.active {
                                            color: $black;
                                        }
                                    }

                                    &:hover > a {
                                        color: $primary;
                                    }

                                    > ul.sub-menu {
                                        width: 100%;
                                        position: static;
                                        left: 100%;
                                        top: 0;
                                        display: none;
                                        margin: 0px;
                                        padding: 0px 40px;
                                        border: solid 0px;
                                        transform: none;
                                        opacity: 1;
                                        visibility: visible;
                                        -webkit-transform: none;
                                        -moz-transform: none;
                                        -ms-transform: none;
                                        -o-transform: none;
                                        transform: none;
                                        -webkit-transition: inherit;
                                        -moz-transition: inherit;
                                        transition: inherit;
                                        -webkit-transform-style: flat;
                                        box-shadow: none;
                                        border: 0;
                                        border-radius: 0;
                                        background: transparent;
                                        > li {
                                            margin: 0px 0px 0px 0px;

                                            > a {
                                                line-height: 20px;
                                                font-size: 13px;
                                                padding: 10px 0px 10px 16px;
                                            }

                                            span + a {
                                                padding-right: 30px;
                                            }

                                            > a {
                                                > .fa {
                                                    display: none;
                                                }

                                                &:hover {
                                                    background-color: transparent;
                                                    color: #576482;
                                                    text-decoration: none;
                                                }

                                                &.active {
                                                    color: $black;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .horizontal-megamenu {
                            color: #576482;
                            display: none;
                            position: relative;
                            top: 0px;
                            padding: 0px 0px;
                            border: solid 0px;
                            transform: none;
                            opacity: 1;
                            visibility: visible;
                            -webkit-transform: none;
                            -moz-transform: none;
                            -ms-transform: none;
                            -o-transform: none;
                            transform: none;
                            -webkit-transition: inherit;
                            -moz-transition: inherit;
                            transition: inherit;
                            -webkit-transform-style: flat;

                            .container {
                                padding: 0;
                            }

                            .title {
                                color: #576482;
                                font-size: 15px;
                                padding: 10px 8px 10px 0px;
                            }

                            > ul {
                                width: 100%;
                                margin: 0px;
                                padding: 0px;
                                font-size: 13px;

                                > li > a {
                                    padding: 9px 14px;
                                    line-height: normal;
                                    font-size: 13px;
                                    background-color: #e7e7e7;
                                    color: #576482;

                                    &:hover {
                                        background-color: $black;
                                    }
                                }
                            }

                            ul li.title {
                                line-height: 26px;
                                color: #576482;
                                margin: 0px;
                                font-size: 15px;
                                padding: 7px 0px;
                                background-color: transparent;
                            }

                            &.halfdiv {
                                width: 100%;
                            }

                            .menu_form {
                                padding: 5px 0px 62px 0px;
                            }

                            .show-grid div {
                                margin: 0px;
                            }

                            .menu_form {
                                input {
                                    &[type="button"],
                                    &[type="submit"] {
                                        width: 46%;
                                    }
                                }

                                textarea {
                                    min-height: 100px;
                                }
                            }
                        }
                    }
                }
            }
        }

        /* ================== Mobile Slide Down Links CSS ================== */
        /* ================== Mobile Mega Menus CSS  ================== */
        /* ================== Mobile Header CSS ================== */

        .horizontal-header {
            width: 100%;
            display: block;
            position: relative;
            top: 0;
            left: 0;
            z-index: 1;
            height: 54px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            text-align: center;
            -webkit-transition: all 0.25s ease-in-out;
            -moz-transition: all 0.25s ease-in-out;
            -o-transition: all 0.25s ease-in-out;
            -ms-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
        }

        &.active .horizontal-header {
            -webkit-transition: all 0.25s ease-in-out;
            -moz-transition: all 0.25s ease-in-out;
            -o-transition: all 0.25s ease-in-out;
            -ms-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
        }

        .horizontal-header .smllogo {
            display: inline-block;
            margin-top: 11px;
        }

        /* Mobile Search Bar*/

        .horizontalMenu > .horizontalMenu-list > li.rightmenu > .topmenusearch {
            width: 86%;
            margin: 7% 7%;
            padding: 0px;

            input {
                border-radius: 50px;

                &:focus {
                    width: 100%;
                }
            }
        }

        /* Mobile Toggle Menu icon (X ICON) */

        .animated-arrow {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 102;
            -webkit-transition: all 0.4s ease-in-out;
            -moz-transition: all 0.4s ease-in-out;
            -o-transition: all 0.4s ease-in-out;
            -ms-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
            cursor: pointer;
            padding: 20px 35px 20px 15px;
            margin: 16px 0 0 18px !important;
            border-radius: 8px;
            border: 0px solid #e9ebfa;
            background: #f1f4fb;

            span {
                cursor: pointer;
                height: 2px;
                width: 17px;
                background: #5c678f;
                position: absolute;
                display: block;
                content: "";
                transition: all 500ms ease-in-out;

                &:before,
                &:after {
                    cursor: pointer;
                    height: 2px;
                    width: 17px;
                    background: #5c678f;
                    position: absolute;
                    display: block;
                    content: "";
                }

                &:before {
                    top: -7px;
                    width: 23px;
                }

                &:after {
                    bottom: -7px;
                    width: 17px;
                }

                &:before,
                &:after {
                    transition: all 500ms ease-in-out;
                }
            }
        }

        &.active .animated-arrow {
            span {
                background-color: transparent;
                background: none !important;

                &:after {
                    width: 23px;
                    top: -1px;
                }

                &:before {
                    top: -1px;
                }
            }

            &.active span:after {
                top: -3px;
            }

            span {
                &:before {
                    transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    bottom: 0px;
                }

                &:after {
                    transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                }
            }
        }

        /* ================== Mobile Overlay/Drawer CSS ================== */

        .horizontal-overlapbg {
            right: 0;
            width: calc(100% - 240px);
            height: 100vh;
            min-height: 100%;
            position: fixed;
            top: 0;
            opacity: 0;
            visibility: hidden;
            background-color: rgba(0, 0, 0, 0.45);
            cursor: pointer;
        }

        &.active .horizontalMenu .horizontal-overlapbg {
            opacity: 1;
            visibility: visible;
            -webkit-transition: opacity 1.5s ease-in-out;
            -moz-transition: opacity 1.5s ease-in-out;
            -ms-transition: opacity 1.5s ease-in-out;
            -o-transition: opacity 1.5s ease-in-out;
        }

        .horizontalMenucontainer {
            -webkit-transition: all 0.25s ease-in-out;
            -moz-transition: all 0.25s ease-in-out;
            -o-transition: all 0.25s ease-in-out;
            -ms-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;
        }

        &.active {
            .horizontalMenucontainer {
                margin-left: 240px;
                -webkit-transition: all 0.25s ease-in-out;
                -moz-transition: all 0.25s ease-in-out;
                -o-transition: all 0.25s ease-in-out;
                -ms-transition: all 0.25s ease-in-out;
                transition: all 0.25s ease-in-out;
            }

            .horizontalMenu {
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
                visibility: visible;
                z-index: 1000;
                top: 0;
                height: 100% !important;

                > .horizontalMenu-list {
                    -webkit-transition: all 0.25s ease-in-out;
                    -moz-transition: all 0.25s ease-in-out;
                    -o-transition: all 0.25s ease-in-out;
                    -ms-transition: all 0.25s ease-in-out;
                    transition: all 0.25s ease-in-out;
                    margin-left: 0;
                }
            }
        }

        /* ================== Mobile Sub Menu Expander Arrows  ================== */

        .horizontalMenu > .horizontalMenu-list > li {
            > .horizontalMenu-click {
                height: 49px;
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                cursor: pointer;
                width: 100%;
            }

            .horizontalMenu-click.horizontal-activearrow > i {
                transform: rotate(180deg);
                margin-top: 10px !important;
                margin-right: 25px !important;
            }
            .horizontalMenu-click.horizontal-activearrow1 > i {
                transform: rotate(180deg);
                margin-top: 10px !important;
                margin-right: 18px !important;
            }

            > {
                .horizontalMenu-click > i {
                    display: block;
                    height: 23px;
                    width: 25px;
                    margin-top: 14px;
                    margin-right: 10px;
                    background-size: 25px;
                    font-size: 16px;
                    color: #576482;
                    float: right;
                    opacity: 0.4;
                }

                ul.sub-menu > li .horizontalMenu-click02 {
                    height: 41px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: block;
                    cursor: pointer;
                    width: 100%;

                    > i {
                        display: block;
                        margin: 8px 6px 8px 6px;
                        background-size: 25px;
                        font-size: 16px;
                        color: #576482;
                        float: right;
                        opacity: 0.4;
                    }
                }
                ul.sub-menu.sub-menu > li .horizontalMenu-click03 {
                    height: 41px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: block;
                    cursor: pointer;
                    width: 100%;

                    > i {
                        display: block;
                        margin: 8px 6px 8px 6px;
                        background-size: 25px;
                        font-size: 16px;
                        color: #576482;
                        float: right;
                        opacity: 0.4;
                    }
                }
                ul.sub-menu.sub-menu.sub-menu > li .horizontalMenu-click04 {
                    height: 41px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: block;
                    cursor: pointer;
                    width: 100%;

                    > i {
                        display: block;
                        margin: 8px 6px 8px 6px;
                        background-size: 25px;
                        font-size: 16px;
                        color: #576482;
                        float: right;
                        opacity: 0.4;
                        position: relative;
                        right: 60px;
                    }
                }
            }
        }

        .sub-menu li:before {
            top: 10px !important;
        }

        .horizontalMenu > .horizontalMenu-list > li {
            > ul.sub-menu {
                .sub-menu-sub:after {
                    display: none;
                }

                > li .horizontalMenu-click02 > i.horizontalMenu-rotate {
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
            > ul.sub-menu.sub-menu {
                .sub-menu-sub:after {
                    display: none;
                }

                > li .horizontalMenu-click03 > i.horizontalMenu-rotate {
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
            > ul.sub-menu.sub-menu.sub-menu {
                .sub-menu-sub:after {
                    display: none;
                }

                > li .horizontalMenu-click04 > i.horizontalMenu-rotate {
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
            a span {
                display: none;
            }

            > {
                a .horizontal-icon {
                    display: none;
                }

                ul.sub-menu > li > ul.sub-menu {
                    background: transparent;
                    box-shadow: none !important;
                }
            }
        }

        .mega-menubg {
            border: 0 !important;
            box-shadow: 0 !important;
        }

        .hor-menu .horizontalMenu > .horizontalMenu-list > li {
            > {
                .horizontal-megamenu .link-list li a {
                    color: #576482;
                }

                a.active {
                    color: $primary;
                    background: transparent;
                }
            }

            &:first-child > a {
                margin-top: 0 !important;
            }

            &:last-child > a {
                margin-right: 5px !important;
            }
        }

        .mega-menubg {
            background: #1c2e46 !important;
            box-shadow: none !important;
            padding: 0px 20px !important;
        }

        /*End Media Query*/
    }

    /* Extra @Media Query*/

    @media only screen and (min-width: 992px) and (max-width: 1162px) {
        .horizontalMenu > .horizontalMenu-list > li {
            > a {
                padding-left: 5px;
                padding-right: 5px;
                font-size: 13px;

                > .arrow {
                    display: none;
                }
            }

            a.menuhomeicon {
                padding-left: 22px;
                padding-right: 22px;
            }
        }
    }
    @media (max-width: 991px){
        .container {
            max-width:85%
        }
    }
}
